import React from 'react';
import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import UnderMaintenanceAnimation from '../assets/lottie/UnderMaintenanceAnimation.json';

const OngoingUpdates = () => {
  useEffect(() => {
    lottie.loadAnimation({
		container: container.current,
    render: 'svg',
    loop: true,
    autoplay: true,
    animationData: UnderMaintenanceAnimation
		})
	}, [])

  const container = useRef(null)

  return (
    <div className="page ongoing-updates-page">
      {/* <h1>XentriAI, Inc.</h1> */}
      <div className="animation-container" ref={container}></div>
      {/* <p className='uppercase'>XentriAI, Inc. is undergoing updates. Please check back later.</p> */}
    </div>
  )
}

export default React.memo(OngoingUpdates);