import './styles/style.css'
import { OngoingUpdates } from './pages';

function App() {
  return (
    <div className="App">
      <OngoingUpdates />
    </div>
  );
}

export default App;
